import { defineComponent } from 'vue'
export default defineComponent({
  methods: {
    onMounted (mainOverflow = 'auto') {
      this.$emit('authentified')
      this.$emit('app-changed-success')
      this.setMainOverflow(mainOverflow)
    },

    /**
     * to call in mounted of each route, set the value of overflow of the html main node.
     * cause the page is not refresh each time the route change, the overflow style may not change when the user change page.
     * calling this function in mounted of each view allow you to set the overflow style
     * OR
     * when you set it in hidden in the 'mounted' of a view, reset it to 'auto' in 'beforeDestroy'
     * @param value auto | hidden
     */
    setMainOverflow (value: any) {
      let html = document.getElementsByTagName('html')[0]
      html.style.overflow = value
    }
  }
})
